<template>
    <v-row justify="center">
        <v-col cols="12">
            <page-toolbar 
                :previous-page-label="$t('console.platforms.mturk.assignments.toolbar.prevPageLabel')" 
                :title="$t('console.platforms.mturk.assignments.toolbar.title')" 
            >
                <template #contents>
                    <approve-assignments-dialog-button
                        :client="client"
                        :assignment-ids="assignmentIds"
                        :checked-assignment-ids="checkedAssignmentIds"
                        @complete="onComplete"
                    />
                    <reject-assignments-dialog-button
                        :client="client"
                        :assignment-ids="assignmentIds"
                        :checked-assignment-ids="checkedAssignmentIds"
                        @complete="onComplete"
                    />
                    <send-bonus-dialog-button
                        :client="client"
                        :assignments="assignments"
                        :checked-assignments="checkedAssignments"
                        @complete="onComplete"
                    />
                </template>
            </page-toolbar>
        </v-col>

        <v-col cols="11">
            <v-card width="500">
                <v-card-text>
                    {{ $t('console.platforms.mturk.assignments.batchSelectLabel') }} 
                    <v-select
                        hide-details 
                        :loading="loadingBatches"
                        v-model="selectedBatch"
                        :items="batches"
                        return-object
                        item-text="name"
                        @change="listAssignmentsForTuttiHITBatch()"
                    />
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="11">
            <v-data-table
                v-if="selectedBatch"
                dense
                sort-desc
                show-select
                :headers="headers"
                :items="assignments"
                item-key="AssignmentId"
                class="elevation-1"
                :loading="loading"
                v-model="checkedAssignments"
                sort-by="Timestamp"
                :items-per-page="100"
                :footer-props="{ itemsPerPageOptions: [10,30,50,100,300] }"
                :search="searchQuery">

                <template #progress>
                    <v-progress-linear stream buffer-value="0" :value="progress" class="px-0"/>
                </template>

                <template #top>
                    <v-card-title>
                        {{ $t('console.platforms.mturk.assignments.table.title', { selectedBatchName: selectedBatch.name }) }}  
                        <v-tooltip bottom>
                            <template #activator="{ attrs, on }">
                                <v-btn v-bind="attrs" v-on="on" icon :loading="loading" @click="listAssignmentsForTuttiHITBatch(false)"><v-icon>mdi-refresh</v-icon></v-btn>
                            </template>
                            <p class="text-center ma-0">Refresh the whole table<br>(Note: this may take minutes to complete,<br>depending on the number of HITs)</p>
                        </v-tooltip>
                        <v-spacer />
                        <v-text-field
                            single-line
                            hide-details
                            v-model="searchQuery"
                            append-icon="mdi-magnify"
                            :label="$t('console.platforms.mturk.assignments.table.searchLabel')"
                            >
                        </v-text-field>
                    </v-card-title>
                    <v-card-text>
                        {{ $t('console.platforms.mturk.assignments.table.text', { lastCachedAt: lastCachedAt }) }}  
                    </v-card-text>
                </template>

                <template #item.WorkerId="{ item }">
                    {{ `${item.WorkerId}` }}
                </template>

                <template #item.AssignmentId="{ item }">
                    <div class="text-truncate" style="max-width:100px;">
                        {{ item.AssignmentId }}
                    </div>
                </template>

                <template #item.AcceptTime="{ item }">
                    {{ standardDateTimeFormat(item.AcceptTime) }}
                </template>

                <template #item.SubmitTime="{ item }">
                    {{ standardDateTimeFormat(item.SubmitTime) }}
                </template>

                <template #item.AutoApprovalTime="{ item }">
                    {{ standardDateTimeFormat(item.AutoApprovalTime) }}
                </template>

            </v-data-table>
        </v-col>

    </v-row>
</template>
<script>
import PageToolbar from '@/components/ui/PageToolbar'
import ApproveAssignmentsDialogButton from './ApproveAssignmentsDialogButton'
import RejectAssignmentsDialogButton from './RejectAssignmentsDialogButton'
import SendBonusDialogButton from './SendBonusDialogButton'
import { standardDateTimeFormat } from '@/lib/utils'
import { onMTurkCredentialsUpdate } from '@/lib/mturk'

export default {
    components: {
        PageToolbar,
        ApproveAssignmentsDialogButton,
        RejectAssignmentsDialogButton,
        SendBonusDialogButton
    },
    data() {
        return {
            headers: [
                { text: this.$t('console.platforms.mturk.assignments.table.headers.assignmentId'), value: "AssignmentId" },
                { text: this.$t('console.platforms.mturk.assignments.table.headers.workerId'), value: "WorkerId" },
                { text: this.$t('console.platforms.mturk.assignments.table.headers.status'), value: "AssignmentStatus" },
                { text: this.$t('console.platforms.mturk.assignments.table.headers.acceptTime'), value: "AcceptTime" },
                { text: this.$t('console.platforms.mturk.assignments.table.headers.submittedTime'), value: "SubmitTime" },
                { text: this.$t('console.platforms.mturk.assignments.table.headers.autoApprovalTime'), value: "AutoApprovalTime" },
            ],
            searchQuery: '',
            selectedHITTypeId: '',

            selected: [],
            assignments: [],
            checkedAssignments: [],
            HITIds: {},
            batches: [],
            selectedBatch: null,

            loading: false,
            progress: 0,
            loadingBatches: false,
            lastCachedAt: null,
        }
    },
    props: ['client', 'credentials'],

    computed: {
        assignmentIds() {
            return this.assignments.map((assignment) => (assignment.AssignmentId));
        },
        checkedAssignmentIds() {
            return this.checkedAssignments.map((assignment) => (assignment.AssignmentId));
        },
    },
    methods: {
        standardDateTimeFormat,
        async listTuttiHITBatches() {
            this.batches = await this.client.mturk.listTuttiHITBatches();
            this.loadingBatches = false;
        },
        listAssignmentsForTuttiHITBatch(cached = true) {
            this.assignments = [];
            this.loading = true;
            this.progress = 0;
            this.client.mturk.listAssignmentsForTuttiHITBatch.send({
                cached,
                batch_id: this.selectedBatch.id,
            });
        },
        onComplete() {
            this.checkedAssignments = [];
        }
    },
    watch: {
        selectedBatch() {
            this.assignments = [];
        }
    },
    mounted() {
        this.loadingBatches = true;
        onMTurkCredentialsUpdate(this, async () => {
            this.selectedBatch = null;
            this.client.mturk.on('listAssignmentsForTuttiHITBatch', {
                success: (data) => {
                    if(data && ('counts' in data)) {
                        this.assignments = data.successes.flat();
                        this.progress = (data.counts.success+data.counts.error)/data.counts.all*100;
                    } else if (data && ('last_cached_at' in data)) {
                        this.lastCachedAt = standardDateTimeFormat(data.last_cached_at);
                        this.loading = false;
                    }
                }
            });
            this.listTuttiHITBatches();
        });
    }
}
</script>
<style>
</style>
