<template>
    <div>
        <combobox-form-dialog-button
            ref="dialog"
            :items="assignmentIds"
            :checked-items="checkedAssignmentIds"
            combobox-label="Assignment IDs"
            :button-label="$t('console.platforms.mturk.assignments.apvAsgmtDialog.title')"
            button-icon="mdi-check-bold"
            :can-confirm="valid"
            :progress="progress"
            @confirm="onConfirm"
            @dialog-open="onDialogOpen"
            @complete="$emit('complete')"
        >
            <template #title>
                {{ $t('console.platforms.mturk.assignments.apvAsgmtDialog.title') }}
                <help-button name="ApproveAssignment" />
            </template>
            <template #body>
                <v-form v-model="valid">
                    <v-text-field dense outlined label="RequesterFeedback" v-model="inputs.requesterFeedback" />
                    <v-switch outlined label="OverrideRejection" v-model="inputs.overrideRejection" />
                </v-form>
            </template>
            <template #alert-body="{ selectedItems }">
                <v-card-text>
                    {{ $t('console.platforms.mturk.assignments.apvAsgmtDialog.text', { selectedItemsLength: selectedItems.length }) }}
                </v-card-text>
            </template>
        </combobox-form-dialog-button>
    </div>
</template>
<script>
import ComboboxFormDialogButton from '../ui/ComboboxFormDialogButton'
import HelpButton from '../ui/HelpButton'
import rules from '@/lib/input-rules'

export default {
    components: {
        ComboboxFormDialogButton,
        HelpButton
    },
    data: () => ({
        rules,
        valid: false,
        progress: {
            shown: false,
            percentage: 0,
            errors: [],
            counts: {}
        },
        inputs: {}
    }),
    props: ['client', 'assignmentIds', 'checkedAssignmentIds'],
    methods: {
        onConfirm(assignmentIds) {
            try {
                this.progress.shown = true;
                this.progress.counts = {
                        success: 0,
                        error: 0,
                        all: assignmentIds.length,
                    },
                this.client.mturk.approveAssignments.send({
                    assignment_ids: assignmentIds,
                    requester_feedback: this.inputs.requesterFeedback,
                    override_rejection: this.inputs.overrideRejection,
                });
            } catch (e) {
                this.progress.shown = false;
                this.$root.$emit('tutti-snackbar', 'error', 'Failed in associating qualifications');
                console.error(e);
            }
        },
        onDialogOpen() {
            this.inputs = {
                    requesterFeedback: '',
                    overrideRejection: false,
                };
            this.progress.shown = false;
            this.progress.errors = [];
        }
    },
    mounted() {
        this.client.invokeOnOpen(() => {
            this.client.mturk.on('approveAssignments', {
                success: (data) => {
                        if(data) {
                            this.progress.counts = data.counts;
                            this.progress.percentage = (data.counts.success+data.counts.error)/data.counts.all*100;
                            this.progress.errors = data.errors;
                        }
                    },
                error: (data) => {
                        this.progress.shown = false;
                        this.$root.$emit('tutti-snackbar', 'error', 'Failed in rejecting assignments');
                        console.error(data);
                    }
            });
        });
    }
}
</script>
