var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("combobox-form-dialog-button", {
        ref: "dialog",
        attrs: {
          items: _vm.assignments,
          "checked-items": _vm.checkedAssignments,
          "item-text": "AssignmentId",
          "combobox-label": "Assignment IDs",
          "button-label": _vm.$t(
            "console.platforms.mturk.assignments.sendBonusDialog.title"
          ),
          "button-icon": "mdi-hand-coin",
          "can-confirm": _vm.valid,
          progress: _vm.progress,
        },
        on: {
          confirm: _vm.onConfirm,
          "dialog-open": _vm.onDialogOpen,
          complete: function ($event) {
            return _vm.$emit("complete")
          },
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "console.platforms.mturk.assignments.sendBonusDialog.title"
                      )
                    ) +
                    " "
                ),
                _c("help-button", { attrs: { name: "SendBonus" } }),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "v-form",
                  {
                    model: {
                      value: _vm.valid,
                      callback: function ($$v) {
                        _vm.valid = $$v
                      },
                      expression: "valid",
                    },
                  },
                  [
                    _c("v-text-field", {
                      attrs: {
                        dense: "",
                        outlined: "",
                        label: "BonusAmount",
                        type: "number",
                        min: "0.01",
                        step: "0.01",
                        prefix: "$",
                        rules: [_vm.rules.required, _vm.rules.number],
                      },
                      model: {
                        value: _vm.inputs.bonusAmount,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputs, "bonusAmount", $$v)
                        },
                        expression: "inputs.bonusAmount",
                      },
                    }),
                    _c("v-text-field", {
                      attrs: {
                        dense: "",
                        outlined: "",
                        label: "Reason",
                        rules: [_vm.rules.required],
                      },
                      model: {
                        value: _vm.inputs.reason,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputs, "reason", $$v)
                        },
                        expression: "inputs.reason",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "alert-body",
            fn: function ({ selectedItems }) {
              return [
                _c("v-card-text", [
                  _vm._v(
                    " Send bonus to " +
                      _vm._s(selectedItems.length) +
                      " assignments? "
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }