var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("combobox-form-dialog-button", {
        ref: "dialog",
        attrs: {
          items: _vm.assignmentIds,
          "checked-items": _vm.checkedAssignmentIds,
          "combobox-label": "Assignment IDs",
          "button-label": _vm.$t(
            "console.platforms.mturk.assignments.rejectAsgmtDialog.title"
          ),
          "button-icon": "mdi-close-thick",
          "can-confirm": _vm.valid,
          progress: _vm.progress,
        },
        on: {
          confirm: _vm.onConfirm,
          "dialog-open": _vm.onDialogOpen,
          complete: function ($event) {
            return _vm.$emit("complete")
          },
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "console.platforms.mturk.assignments.rejectAsgmtDialog.title"
                      )
                    ) +
                    " "
                ),
                _c("help-button", { attrs: { name: "RejectAssignment" } }),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "v-form",
                  {
                    model: {
                      value: _vm.valid,
                      callback: function ($$v) {
                        _vm.valid = $$v
                      },
                      expression: "valid",
                    },
                  },
                  [
                    _c("v-text-field", {
                      attrs: {
                        dense: "",
                        outlined: "",
                        label: "RequesterFeedback",
                        rules: [_vm.rules.required],
                      },
                      model: {
                        value: _vm.inputs.requesterFeedback,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputs, "requesterFeedback", $$v)
                        },
                        expression: "inputs.requesterFeedback",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "alert-body",
            fn: function ({ selectedItems }) {
              return [
                _c("v-card-text", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "console.platforms.mturk.assignments.rejectAsgmtDialog.text",
                          { selectedItemsLength: selectedItems.length }
                        )
                      ) +
                      " "
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }