<template>
    <div>
        <combobox-form-dialog-button
            ref="dialog"
            :items="assignments"
            :checked-items="checkedAssignments"
            item-text="AssignmentId"
            combobox-label="Assignment IDs"
            :button-label="$t('console.platforms.mturk.assignments.sendBonusDialog.title')"
            button-icon="mdi-hand-coin"
            :can-confirm="valid"
            :progress="progress"
            @confirm="onConfirm"
            @dialog-open="onDialogOpen"
            @complete="$emit('complete')"
        >
            <template #title>
                {{ $t('console.platforms.mturk.assignments.sendBonusDialog.title') }}
                <help-button name="SendBonus" />
            </template>
            <template #body>
                <v-form v-model="valid">
                    <v-text-field dense outlined label="BonusAmount" type="number" v-model="inputs.bonusAmount" min="0.01" step="0.01" prefix="$" :rules="[rules.required, rules.number]" />
                    <v-text-field dense outlined label="Reason" v-model="inputs.reason" :rules="[rules.required]" />
                </v-form>
            </template>
            <template #alert-body="{ selectedItems }">
                <v-card-text>
                    Send bonus to {{ selectedItems.length }} assignments?
                </v-card-text>
            </template>
        </combobox-form-dialog-button>
    </div>
</template>
<script>
import ComboboxFormDialogButton from '../ui/ComboboxFormDialogButton'
import HelpButton from '../ui/HelpButton'
import rules from '@/lib/input-rules'

export default {
    components: {
        ComboboxFormDialogButton,
        HelpButton
    },
    data: () => ({
        rules,
        valid: false,
        progress: {
            shown: false,
            percentage: 0,
            errors: [],
            counts: {}
        },
        inputs: {}
    }),
    props: ['client', 'assignments', 'checkedAssignments'],
    methods: {
        onConfirm(assignments) {
            try {
                this.progress.shown = true;
                this.progress.counts = {
                        success: 0,
                        error: 0,
                        all: assignments.length,
                    },
                this.client.mturk.sendBonus.send({
                    worker_ids: assignments.map((assignment) => (assignment.WorkerId)),
                    bonus_amount: this.inputs.bonusAmount,
                    assignment_ids: assignments.map((assignment) => (assignment.AssignmentId)),
                    reason: this.inputs.reason,
                });
            } catch (e) {
                this.progress.shown = false;
                this.$root.$emit('tutti-snackbar', 'error', 'Failed in associating qualifications');
                console.error(e);
            }
        },
        onDialogOpen() {
            this.inputs = {
                    bonusAmount: '',
                    reason: ''
                };
            this.progress.shown = false;
            this.progress.errors = [];
        }
    },
    mounted() {
        this.client.invokeOnOpen(() => {
            this.client.mturk.on('sendBonus', {
                success: (data) => {
                        if(data) {
                            this.progress.counts = data.counts;
                            this.progress.percentage = (data.counts.success+data.counts.error)/data.counts.all*100;
                            this.progress.errors = data.errors;
                        }
                    },
                error: (data) => {
                        this.progress.shown = false;
                        this.$root.$emit('tutti-snackbar', 'error', 'Failed in rejecting assignments');
                        console.error(data);
                    }
            });
        });
    }
}
</script>
